import { Box } from "@myonlinestore/bricks";
import styled from "styled-components";

type PropsType = {
  variant?: "white" | "light" | "offwhite" | "dark" | "darker" | "grey";
  boxOffset?: boolean;
  bleed?: boolean;
  stretch?: boolean;
};

const Section = styled(Box)<PropsType>`
  box-sizing: border-box;
  color: #000;
  width: 100%;

  max-width: ${({ bleed }) => (bleed === false ? "100%" : "1400px")};

  padding-left: ${({ bleed }) => (bleed === false ? "calc(50% - 676px)" : "24px")};
  padding-right: ${({ bleed }) => (bleed === false ? "calc(50% - 676px)" : "24px")};

  margin-left: ${({ bleed }) => (bleed === false ? "" : "auto")};
  margin-right: ${({ bleed }) => (bleed === false ? "" : "auto")};

  margin-top: ${({ boxOffset }) => (boxOffset === false ? "" : "100px")};
  margin-bottom: ${({ boxOffset }) => (boxOffset === false ? "" : "100px")};
  padding-top: ${({ boxOffset }) => (boxOffset === false ? "" : "25px")};
  padding-bottom: ${({ boxOffset }) => (boxOffset === false ? "" : "25px")};

  width: 100%;
  min-height: ${({ stretch }) => (stretch === true ? "50vh" : "")};
  min-height: "55vh";

  background: ${({ variant }) => {
    if (variant === "white") {
      return "#fff";
    }
    if (variant === "light") {
      return "#f9f9f9";
    }
    if (variant === "grey") {
      return "#EFEFEF";
    }
    return "transparent";
  }};

  & h1,
  & h2,
  & h3,
  & h4 {
    color: ${({ variant }) =>
      variant === "dark" || variant === "darker" ? "#fff" : "#000"};
  }

  // @media screen and (max-width: 1448px) {
  @media screen and (max-width: 1400px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media screen and (max-width: 960px) {
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    padding: 24px 20px;

    margin-top: ${({ boxOffset }) => (boxOffset === false ? "" : "50px")};
    margin-bottom: ${({ boxOffset }) => (boxOffset === false ? "" : "50px")};
  }

  @media screen and (max-width: 600px) {
    margin-top: ${({ boxOffset }) => (boxOffset === false ? "" : "24px")};
    margin-bottom: ${({ boxOffset }) => (boxOffset === false ? "" : "24px")};
  }
`;

export default Section;
