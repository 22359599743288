import React, { FC } from "react";
import Partner from "./Partner";
import partners from "./partners";
import Title from "../Title";
import styled from "styled-components";

const StyledPartnerWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Maintain original 4-column layout */
  column-gap: 24px;
  row-gap: 24px;
  margin: 24px auto 12px auto;
  max-width: 1400px;
  width: 100%;

  @media screen and (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr); /* Switch to 2 columns for medium screens */
    column-gap: 12px;
    row-gap: 12px;
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr; /* Single column for small screens */
    column-gap: 6px;
    row-gap: 6px;
  }
`;

const PartnersSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  width: 100%;
`;

interface PartnerType {
    src: string;
    url: string;
    alt: string;
    id: string; // Add a unique identifier
}

const Partners: FC = (): JSX.Element => (
    <PartnersSection>
        <Title as="h3">Partners van Care for IT</Title>
        <StyledPartnerWrapper>
            {partners.map((partner: PartnerType) => (
                <Partner
                    key={partner.id}
                    src={partner.src}
                    href={partner.url}
                    alt={partner.alt}
                />
            ))}
        </StyledPartnerWrapper>
    </PartnersSection>
);

export default Partners;
