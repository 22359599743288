import "./App.css";
import { MosTheme, Text, Box } from "@myonlinestore/bricks";
import Contact from "./components/Contact";
import Footer from "./components/Footer/index";
import Headroom from "react-headroom";
import Menu from "./components/Menu";
import Partners from "./components/Partners";
import React, { FC } from "react";
import Section from "./components/Section";
import TeamViewer from "./components/Teamviewer";
import TextOnImage from "./components/TextOnImage/index";
import Title from "./components/Title";
import Main from "./components/Main";
import Image from "./components/Image";
import certificateImage from "./assets/images/certificate.png";
const App: FC = () => {
  const value = false;
  return (
    <div className="App">
      <MosTheme>
        <Headroom>
          <Section boxOffset={value ? true : false} bleed={false} variant="white">
          <Menu />
          </Section>
        </Headroom>

        <Main>
          <Section id="about" width="100%" margin={["auto"]}>
            <TextOnImage>
              <Title as="h3">
                Over Care for IT
              </Title>
              <Box margin={[24, 0, 0]}>
                <Text textAlign="left">
                  Care for IT, onderdeel van KTC Beheer B.V., is een dienstverlenend
                  bedrijf met meer dan 30 jaar ervaring op het gebied van IT,
                  Netwerken, Servers en infrastructuur voor industrie en MKB. Wij
                  hebben zorg voor het optimaal functioneren van uw IT. Wij
                  adviseren, leveren en onderhouden uw netwerk, lokaal en remote.
                  Bij Care for IT kunt u daarnaast terecht voor Rackmount Servers,
                  PC’s, notebooks en wat u verder ook maar nodig heeft om de IT van
                  uw bedrijf efficiënt en kostenvriendelijk te beheren.
                </Text>
              </Box>
            </TextOnImage>
          </Section>

          <Section id="teamviewer" stretch bleed={false} variant="light">
            <TeamViewer />
          </Section>

          <Section id="partners"
            padding={[12, 0]}
            margin={["auto"]}
            justifyContent="center"
            bleed
          >
            <Partners />
          </Section>

          <Section id="contact"
            justifyContent="space-between"
            variant="light"
            stretch
            bleed={false}
            alignItems="center"
          >
            <Contact />
          </Section>

          <Section id="certification" bleed={false} variant="white">
            <Box direction="column" alignItems="center" margin={[24, "auto"]}>
              <Title as="h3" textAlign="center">
                SecPoint Partnership Certification
              </Title>
              <Box margin={[24, 0, 0]}>
              <Text textAlign="center">
                We zijn er trots op om officiële partners te zijn van SecPoint Cyber ​​​​Security. Hieronder vindt u onze certificering, <br />
                die onze toewijding aan het leveren van veilige en betrouwbare IT-oplossingen vertegenwoordigt.
              </Text>
              </Box>
              <Image
                src={certificateImage}
                alt="SecPoint Partnership Certificate"
                width="100%"
              />
              <Text textAlign="center">
                <a href="https://www.secpoint.com" target="_blank" rel="noopener noreferrer">
                  Lees meer over SecPoint Cyber Security
                </a>
              </Text>
            </Box>
          </Section>

        </Main>
        <Footer />
      </MosTheme>
    </div>
  );
};

export default App;
